import axios from './index'

export default {
  createOrGetByAppointmentId(isHost, appointmentId) {
    return axios.get("/meeting/createOrGetByAppointmentId?isHost=" + isHost + "&appointmentId=" + appointmentId)
      .then(response => {
        return response;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getCustomerDetails(guestID) {
    return axios.get("/Customer/GetCustomerForOnlineMeeting?id=" + guestID)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getAppointments(guestID) {
    return axios.get("/Appointment/GetAppointmentsForOnlineMeeting?customerId=" + guestID)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  endMeeting(id) {
    return axios.post("/meeting/EndMeeting?meetingId=" + id)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getMultimedia(id) {
    return axios.get("/Meeting/GetMultimedia?meetingId=" + id)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  getOnlineMeetingDetails(id) {
    return axios.get("/Meeting/GetOnlineMeetingDetails?meetingId=" + id)
      .then(response => {
        return response.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
}
