<template>
  <div class="row"
       id="meetingMultimedia"
       style="display:flex; justify-content:flex-start; flex-wrap:wrap;">
    <div v-for="(media,index) in multimediaList" :key="index">
      <div v-if="media.fileExtension=='.jpg' || media.fileExtension=='.png' || media.fileExtension=='.jpeg' "
           style=" margin-top: 15px !important;margin-bottom: 15px !important; margin-right:5px;">
        <a :href="media.url"
           class="thumbnail"
           :data-image-id="media.id"
           :data-title="media.fileName"
           :data-image="media.url"
           target="_blank">
          <img :id="'img_'+media.id"
               class="img-thumbnail"
               :src="media.url"
               style="height:130px !important; width:130px !important;">
        </a>
      </div>
      <div v-else
           style=" margin-top: 15px !important;margin-bottom: 15px !important; margin-right:5px;">
        <div>
          <a :href="media.url"
             class="thumbnail"
             :data-image-id="media.id"
             data-toggle="tooltip"
             :data-title="media.fileName"
             :data-image="media.url"
             target="_blank">
            <img class="img-thumbnail"
                 :src="'https://via.placeholder.com/165x165?text='+media.fileExtension"
                 style="height:130px !important; width:130px !important;">
            <span style="font-size:10px;">{{ media.fileName }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MeetingService from '../../../services/meeting.js'

  export default {
    name: 'OnlineMeetingMultimedia',
    props: {
      meeting: null
    },
    data: function () {
      return {
        multimediaList: []
      }
    },
    methods: {
      getMultimedia() {
        MeetingService.getMultimedia(this.meeting.id)
          .then(response => {
            this.multimediaList = response;
          });
      }
    },
    created: function () {
      this.$bus.$on('RefreshMultimediaList', () => {
        this.multimediaList = [];
        this.getMultimedia();
      });
    },
    mounted: function () {
      this.getMultimedia();
    }
  }
</script>
